.custom-file-upload {
    width: 100px;
    height: 100px;
    border-radius:50%;
    /* //color */
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.profileImg{
    height: 200px;
    width: 200px;
    border-radius: 100px;
}

input[type="file"] {
    display: none;
} 