/* * {
  border: 2px solid red;
} */

.publicCalendar{
  border-radius: 0px !important;
  height: 100% !important;
  width: 100% !important;
  font-size:13px !important;
  /* background-color: red !important; */
  box-shadow: none !important;
  border-radius:1.5rem !important;
}

.Calendar__day{
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.Calendar__day:hover{
  border-radius: 9999px !important;
  
}

.eventDays{
  background-color: #f5f5f5 !important;
  color: whitesmoke !important;
  font-weight: bolder !important;
  border-radius: 9999px !important;
  background-color: #149BEB !important;
}

@media only screen and (max-width: 450px) {
  .Calendar__day{
    border-radius: 10px !important;
  }
  .Calendar__day:hover{
    border-radius: 10px !important;
  }
  .eventDays{
    border-radius: 10px !important;
  }
}


.responsiveCalendar{
  font-size: 12px !important;
  padding: 10px !important;
  width: 100% !important;
}

@media only screen and (max-width: 600px) {
  .responsiveCalendar{
    font-size: 9px !important;
    padding:10px 5% !important;
  }
}
/* rev says don't touch [he will refactor later] */

.currentRequest::-webkit-scrollbar {
  width: 0px;
}
.currentRequest::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #f5f5f5;
}
.currentRequest::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.userdiv {
  animation-name: slide;
  animation-duration: 180ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.userdivback {
  animation-name: sliderev;
  animation-duration: 180ms;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes slide {
  from {
    right: 100%;
    left: 0px;
  }
  to {
    left: 50%;
    right: 0px;
  }
}

@keyframes sliderev {
  from {
    left: 50%;
    right: 0px;
  }
  to {
    right: 100%;
    left: 0px;
  }
}
